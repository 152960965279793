import Vue from "vue";
import Router from "vue-router";
import Auth from "../views/Auth.vue";
import Dashboard from "../views/admin/Dashboard.vue";
import NotFound from "../views/admin/NotFound.vue";
import store from "./store.js";

Vue.use(Router);

let router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [{
        path: "/auth",
        name: "appAuth",
        component: Auth,
    },
        {
            path: "/",
            name: "dashboard",
            component: Dashboard,
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: "users/",
                    name: "userListRoute",
                    component: () =>
                        import (
                            /* webpackChunkName: "users" */
                            "../views/admin/users/UserList.vue"
                            ),
                },
                {
                    path: "schedule/:type/:id",
                    name: "scheduleListRoute",
                    component: () =>
                        import (
                            /* webpackChunkName: "users" */
                            "../views/admin/schedule/ScheduleList.vue"
                            ),
                },
                {
                    path: "bind/:id",
                    name: "bindDoctor",
                    component: () =>
                        import (
                            /* webpackChunkName: "users" */
                            "../views/admin/users/BindDoctor.vue"
                            ),
                },
                {
                    path: "payments",
                    name: "paymentsListRoute",
                    component: () =>
                        import (
                            /* webpackChunkName: "payments" */
                            "../views/admin/payments/PaymentsList.vue"
                            ),
                },
                {
                    path: "dicts/",
                    name: "dictsListRoute",
                    component: () =>
                        import (
                            /* webpackChunkName: "dicts" */
                            "../views/admin/dicts/DictsList.vue"
                            ),
                },
                {
                    path: "services/",
                    name: "servicesListRoute",
                    component: () =>
                        import (
                            /* webpackChunkName: "dicts" */
                            "../views/admin/services/ServicesList.vue"
                            ),
                },
                {
                    path: "service-orders/",
                    name: "serviceOrdersListRoute",
                    component: () =>
                        import (
                            /* webpackChunkName: "dicts" */
                            "../views/admin/service_orders/ServiceOrdersList.vue"
                            ),
                },
                {
                    path: "feedback/",
                    name: "feedbacksListRoute",
                    component: () =>
                        import (
                            /* webpackChunkName: "requests" */
                            "../views/admin/feedbacks/FeedbacksList.vue"
                            ),
                },
                {
                    path: "dashboard/",
                    name: "dashboardListRoute",
                    alias: '/',
                    component: () =>
                        import (
                            /* webpackChunkName: "requests" */
                            "../views/admin/dashboard/DashboardList.vue"
                            ),
                },
                {
                    path: "requests/",
                    name: "requestsListRoute",
                    component: () =>
                        import (
                            /* webpackChunkName: "requests" */
                            "../views/admin/requests/requestsList.vue"
                            ),
                }
            ],
        },
        {
            path: "**",
            name: "notFound",
            component: NotFound,
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
            return;
        }
        next("/auth");
    } else {
        next();
    }
});
export default router;
