import Vue from "vue";
import Vuex from "vuex";
import { appAuth } from "./store/app-auth";
import { reference } from "./store/reference";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: appAuth,
    reference: reference
  }
});
