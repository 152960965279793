<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  created() {
    this.$http.interceptors.response.use(undefined, err => {
      return new Promise(() => {
        if (
          err.response.status === 401 &&
          err.response.config &&
          !err.response.config.__isRetryRequest
        ) {
          this.$store.dispatch("logout");
        }
        throw err;
      });
    });
  }
};
</script>

<style lang="scss">
@import "./assets/style/app.scss";
</style>
