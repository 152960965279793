import Vue from "vue";
import moment from "moment";
Vue.filter("fullDate", value => {
  if (!value) return "";
  return moment(value, moment.defaultFormatUtc).format("DD-MM-YYYY");
});
Vue.filter("fullDateTime", value => {
  if (!value) return "";
  return moment(value, moment.defaultFormatUtc).format("DD-MM-YYYY HH:mm:ss");
});
