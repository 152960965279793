import Vue from "vue";
import "./plugins/fontawesome";
import App from "./App.vue";
import router from "./modules/router";
import store from "./modules/store";
import "./plugins/element.js";
import "./plugins/element.js";
import Axios from "axios";
import "./modules/filters";
import money from "v-money";
// import infiniteScroll from 'vue-infinite-scroll'




// register directive v-money and component <money>
Vue.use(money, {
    decimal: ".",
    thousands: " ",
    prefix: "",
    suffix: "",
    precision: 0,
    masked: false
});
import VueTheMask from "vue-the-mask";

// Vue.use(infiniteScroll)
Vue.use(VueTheMask);

import GoBack from "@/components/GoBack";
Vue.component("GoBack", GoBack);
Vue.config.productionTip = false;
Vue.prototype.$http = Axios;
Vue.prototype.$getErrorText = function (err) {
    if (err.message && !err.response) {
        return err.message;
    } else if (err.response && err.response.data) {
        return err.response.data.message;
    } else if (typeof err === "boolean") {
        return "Проверьте корректность данных";
    } else {
        return err;
    }
};
const token = localStorage.getItem("accessToken");
if (token) {
    Vue.prototype.$http.defaults.headers.common["Token"] ='Bearer '+ token;
    Vue.prototype.$http.defaults.headers.common.Authorization = 'Bearer ' + token
}
store.dispatch('getCities')
store.dispatch('getProfessions')
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
