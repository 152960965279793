<template>
  <el-container>
    <el-header style="text-align: right; font-size: 12px">
      <HeaderComponent></HeaderComponent>
    </el-header>
    <el-container>
      <el-aside style="min-height: 100vh;background-color: white; border-right: gainsboro 1px solid" width="250px">
        <el-menu :router="true" :unique-opened="false" style="position: fixed; border: none; width: 249px">
          <el-menu-item :route="{ name: 'requestsListRoute' }" index="requestsListRoute">Заявки с сайта</el-menu-item>
<!--          <el-menu-item :route="{ name: 'dashboardListRoute' }" index="dashboardListRoute">Обзор показателей</el-menu-item>-->
          <el-menu-item :route="{ name: 'userListRoute' }" index="user">Список пользователей</el-menu-item>
          <el-menu-item :route="{ name: 'paymentsListRoute' }" index="paymentsListRoute">Список оплат</el-menu-item>
          <el-menu-item :route="{ name: 'dictsListRoute' }" index="dictsListRoute">Справочники</el-menu-item>
          <el-menu-item :route="{ name: 'servicesListRoute' }" index="servicesListRoute">Услуги</el-menu-item>
          <el-menu-item :route="{ name: 'serviceOrdersListRoute' }" index="serviceOrdersListRoute">Заказы пользователей</el-menu-item>
          <el-menu-item :route="{ name: 'feedbacksListRoute' }" index="feedbacksListRoute">Отзывы</el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view/>

      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import HeaderComponent from "@/views/admin/Header";

export default {
  components: {HeaderComponent},
  data() {
    return {
      activeLink: null
    };
  },
  watch: {
    $route(to) {
      this.activeLink = to.path;
    }
  },
  mounted() {
    this.activeLink = this.$route.path;
  }
};
</script>

<style>
.el-header {
  background-color: #ffffff;
  border-bottom: 1px solid #dbdbdb;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
</style>
