<template>
  <div class="login">
    <el-card>
      <el-form
          ref="form"
          :model="model"
          :rules="rules"
          class="login-form"
          @submit.native.prevent="login"
      >
        <el-form-item prop="username">
          <el-input
              v-model="model.username"
              placeholder="Электронный адрес"
              prefix-icon="fas fa-user"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              v-model="model.password"
              placeholder="Пароль"
              prefix-icon="fas fa-lock"
              type="password"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
              :loading="loading"
              block
              class="login-button"
              native-type="submit"
              type="primary"
          >Войти в систему
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "appAuth",
  data() {
    return {
      model: {
        username: "",
        password: ""
      },
      loading: false,
      rules: {
        username: [
          {
            required: true,
            message: "Введите электронный адрес",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "Введите пароль",
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted() {
    if (this.$store.getters.isLoggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    async login() {
      let valid = await this.$refs.form.validate();
      if (!valid) {
        return;
      }
      this.loading = true;
      try {
        await this.$store.dispatch("login", {
          email: this.model.username,
          password: this.model.password
        });
        this.$message.success("Вы успешно вошли в систему");
        this.$router.push({name: "dashboard"});
      } catch (e) {
        this.$message.error("Неверный логин или пароль");
      }

      this.loading = false;
    }
  }
};
</script>

<style scoped>
.login {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-button {
  width: 100%;
  margin-top: 40px;
}

.login-form {
  width: 290px;
}

.forgot-password {
  margin-top: 10px;
}
</style>
