import axios from "axios";

export const reference = {
    state: {
        professions: [],
        modules: [],
        departments: [],
        cities: [],
        operations: [],
        fileTypes: []
    },
    mutations: {
        setProfessions(state, modules) {
            state.professions = modules
        },
        setModules(state, modules) {
            state.modules = modules;
        },
        setDepartments(state, departments) {
            state.departments = departments;
        },
        setCities(state, cities) {
            state.cities = cities;
        },
        setOperations(state, operations) {
            state.operations = operations;
        },
        setFileTypes(state, fileTypes) {
            state.fileTypes = fileTypes;
        }
    },
    actions: {
        // eslint-disable-next-line no-unused-vars
        async getModules(context, payload) {
            // eslint-disable-next-line no-useless-catch
            try {
                const result = await axios.get("/v1/modules/list");
                context.commit("setModules", result.data.items);
            } catch (e) {
                throw e;
            }
        },
        // eslint-disable-next-line no-unused-vars
        async getOperations(context, payload) {
            // eslint-disable-next-line no-useless-catch
            try {
                const result = await axios.get("/v1/operations/list");
                context.commit("setOperations", result.data.items);
            } catch (e) {
                throw e;
            }
        },
        // eslint-disable-next-line no-unused-vars
        async getDepartments(context, payload) {
            // eslint-disable-next-line no-useless-catch
            try {
                const result = await axios.get("/v1/departments/list", {
                    params: {
                        // offset: 0,
                        // limit: 100
                    }
                });
                context.commit("setDepartments", result.data.items);
            } catch (e) {
                throw e;
            }
        },
        async getCities(context) {
            // eslint-disable-next-line no-useless-catch
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/web/dictionaries/cities`, {
                    params: {
                        // offset: 0,
                        // limit: 100
                    }
                });
                context.commit("setCities", result.data.items);
            } catch (e) {
                throw e;
            }
        },
        async getProfessions(context) {
            // eslint-disable-next-line no-useless-catch
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/web/dictionaries/professions`, {})
                context.commit('setProfessions', result.data)
            } catch (e) {
                throw e
            }
        },
        async getFileTypes(context) {
            // eslint-disable-next-line no-useless-catch
            try {
                const result = await axios.get("/v1/filetypes/list");
                context.commit("setFileTypes", result.data.items);
            } catch (e) {
                throw e;
            }
        }
    }
};
