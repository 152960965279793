<template>
  <el-button :type="type" @click="goBack"
    ><span v-html="label"></span
  ></el-button>
</template>

<script>
export default {
  name: "GoBack",
  props: {
    label: {
      type: String,
      default: "Отмена"
    },
    type: {
      type: String,
      default: "warning"
    },
    route: {
      type: Object,
      default: () => {
        return {
          name: "dashboard"
        };
      }
    }
  },
  methods: {
    goBack() {
      this.$emit('closePopup')
    }
  }
};
</script>

<style scoped></style>
