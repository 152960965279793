<template>
  <el-row justify="space-between" type="flex">
    <div style="display: flex; align-items: center; justify-content: center; align-content: center">
      <img height="40" src="../../assets/logo.svg">
    </div>
    <div>
      <span v-if="user" class="nav-user">{{ user.surname }}</span>
      <el-button type="danger" @click="logout">Выйти</el-button>
    </div>
  </el-row>
</template>

<script>
export default {
  name: "Header",
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch("logout");
      this.$router.push("/auth").catch(() => {
      });
    }
  },
  async created() {
    try {
      // await this.$store.dispatch("getUser");
      // await this.$store.dispatch("getModules");
    } catch (e) {
      this.$message.info({
        showClose: true,
        message: "Ваша сессия истекла, пожалуйста авторизуйтесь сново",
        duration: 30000
      });
      await this.logout();
    }
  }
};
</script>

<style scoped>
.nav-user {
  font-weight: bold;
  margin-right: 10px;
}

.logo {
  text-decoration: none;
  font-size: 16px;
  color: #000;
}

.logo:hover {
  color: rgba(0, 0, 0, 0.7);
  transform: scale(1.1);
}
</style>
