<template>
  <el-row type="flex" justify="center">
    <el-col :span="20">
      <el-card>
        <h1>404</h1>
        <p>Страница не найдена</p>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "NotFound"
};
</script>

<style></style>
