import axios from "axios";

export const appAuth = {
    state: {
        // user: null,
        user: JSON.parse(localStorage.getItem("user")) || "",
        token: localStorage.getItem("accessToken") || "",
    },
    getters: {
        isLoggedIn: (state) => !!state.token,
        user: (state) => state.user,
        isAdmin: (state) => state.user.is_admin,
    },
    mutations: {
        authSuccess(state, data) {
            state.token = data;
        },
        setUser(state, data) {
            state.user = data;
        },
        logout(state) {
            state.token = "";
        },
    },
    actions: {
        async login(context, payload) {
            let url = process.env.VUE_APP_API_URL;
            try {
                const result = await axios.post(`${url}/admin/login`, {
                    email: payload.email,
                    password: payload.password,
                    device_name: 'web'
                });
                const accessToken = result.data.token;
                localStorage.setItem("accessToken", accessToken);
                axios.defaults.headers.common["Token"] = 'Bearer '+accessToken
                axios.defaults.headers.common.Authorization = 'Bearer ' + accessToken
                context.commit("authSuccess", accessToken);
            } catch (e) {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("user");
                throw e;
            }
        },
        async logout(context) {
            // eslint-disable-next-line no-useless-catch
            try {
                context.commit("logout");
                localStorage.removeItem("accessToken");
                localStorage.removeItem("user");
                delete axios.defaults.headers.common["Token"];
            } catch (e) {
                throw e;
            }
        },
        // async getUser(context) {
        //     try {
        //         const user = await axios.get(`${url}/v1/users/me`);
        //         context.commit("setUser", user.data);
        //     } catch (e) {
        //         context.dispatch("logout");
        //         throw e;
        //     }
        // },
    },
};
